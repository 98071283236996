import './App.css';
import { useEffect, useState } from 'react';
// import { makeStyles } from '@mui/material/styles';
import { Button, Box, Typography } from '@material-ui/core';
import axios from 'axios';
// import { connect, StringCodec, JSONCodec } from "nats.ws/nats.js";
import { connect, JSONCodec, jwtAuthenticator, credsAuthenticator } from "../node_modules/nats.ws/lib/src/mod.js"


const NATS_CONFIG = {
  servers: ["wss://dev-test-ssl-nats.swanforlife.link"], //https://nats-client-3-69-45-0.swanforlife.link
  // user: "react_native",
  // pass: "BQ5ZsV3Ve4cEawvA"
}

// rn core api ->
// let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJRVDJaQlVDN1o3TFJLQ0VUNjVDSURBRVMyTEw2QVJBWUtGNjZVWFBCTEdJUUpGU09WVE9BIiwiaWF0IjoxNjM2NjI5OTE0LCJpc3MiOiJBRE9QSVVKTEJCVTM2S0JON0VHNEpUWVFXNElENkszWE0zSURVMlNIVEcySTRDNVRJRTU1UVJTSSIsIm5hbWUiOiJjb3JlYXBpX2FkbWluX3UiLCJzdWIiOiJVQTJJN0c2UEFSQVlRSkxFVzNHWENZTE5NQU5QMjRaRldSTk1VQVpOM0RYRUNHSVNIM0pGVlNURCIsIm5hdHMiOnsicHViIjp7fSwic3ViIjp7fSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.47s29mLvWwb1F8ltCbyHRdUIno7tgsjxFnDgjolhk208ZAwm39oGcRRaMuBEIaVf9R7GdCc0W9OkSSgTjwJ8AQ";
// let useed = "SUALL26W2VSAHY7DWZWDXVXM3VG6ZW45ZG23LS52CBXMKVLKA4XB7NVTNI";

// user -> 
// let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJaMkpDNkY3RkhMUE40NTdaQVRYVlBDS0U1MzZaNDNHWlNDM0hKV01RTVo3SVlZVkhMWENRIiwiaWF0IjoxNjM2NjQ2ODI5LCJpc3MiOiJBQkdQT1RFU0o1VzZWN05URVpNSTc3WU5TQ01TSFlJSTRGTVhRM0dPNjdNTlo0RzZMRUpDUTNOVyIsIm5hbWUiOiJzd2FuX3Rlc3RfVTEiLCJzdWIiOiJVQUg0S1RCSUZKVVhCTlpTR0tTQ1pFTDQ0UVVURklOUDJUTFNKWTROT01QQkJNQlFJSURGV0JMTCIsIm5hdHMiOnsicHViIjp7ImRlbnkiOlsiXHUwMDNlIl19LCJzdWIiOnsiYWxsb3ciOlsic3dhbi50ZXN0Llx1MDAzZSJdfSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.GVE2BeNudPpHXG9qXe0CH6Y767DrCGxepb4g_V8p53JxGwxuwArgEXEzwGaQG2g5Ur4x1LvFmumxk2aWfkm1Bg";
// let useed = "SUAEHJQFAWDODMI2BGQPLTYBQ3KGVX77AZ4OYDNDETDEZV27TQX424ZD54";
// user -> 
// let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJSRDJWQlJUUVFLT001MjZXQU1XNDdLSTQ3SkJaN0tHWVNNWjVOWVQ1S1lBTFRNRlFGQTNRIiwiaWF0IjoxNjM2NzE5NTc5LCJpc3MiOiJBQkdQT1RFU0o1VzZWN05URVpNSTc3WU5TQ01TSFlJSTRGTVhRM0dPNjdNTlo0RzZMRUpDUTNOVyIsIm5hbWUiOiIxYTM1YTkwODJmMDMyNzM5Yzk5YjZiNTQzZmE4NTViNSIsInN1YiI6IlVCNFgySzQzVUlQSFdXQ1VNUFdOWExFNzJEUU5JR1pITUsyN1RaR0E2NVQzN0xQTkZOVFdJT0NEIiwibmF0cyI6eyJwdWIiOnsiZGVueSI6WyJcdTAwM2UiXX0sInN1YiI6eyJhbGxvdyI6WyJkZXYuc3dhbi5jb3JlYXBpLlx1MDAzZSJdfSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.5rlEfG9HX3gU93F2Fn5gKMuMYcFhIGXJtT4zF0rtiD_NARX6S0DH5TMdFCzyACABVm0yilyCaK9_St7C7uaaBA"
// let useed = "SUAL6XL7UCBUDGHRSWFAEYSSLK2Y3ZHYI23GCMV5XGWXYHFMGHZKCM62II";
// let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJSRDJWQlJUUVFLT001MjZXQU1XNDdLSTQ3SkJaN0tHWVNNWjVOWVQ1S1lBTFRNRlFGQTNRIiwiaWF0IjoxNjM2NzE5NTc5LCJpc3MiOiJBQkdQT1RFU0o1VzZWN05URVpNSTc3WU5TQ01TSFlJSTRGTVhRM0dPNjdNTlo0RzZMRUpDUTNOVyIsIm5hbWUiOiIxYTM1YTkwODJmMDMyNzM5Yzk5YjZiNTQzZmE4NTViNSIsInN1YiI6IlVCNFgySzQzVUlQSFdXQ1VNUFdOWExFNzJEUU5JR1pITUsyN1RaR0E2NVQzN0xQTkZOVFdJT0NEIiwibmF0cyI6eyJwdWIiOnsiZGVueSI6WyJcdTAwM2UiXX0sInN1YiI6eyJhbGxvdyI6WyJkZXYuc3dhbi5jb3JlYXBpLlx1MDAzZSJdfSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.5rlEfG9HX3gU93F2Fn5gKMuMYcFhIGXJtT4zF0rtiD_NARX6S0DH5TMdFCzyACABVm0yilyCaK9_St7C7uaaBA"
// let useed = "SUAL6XL7UCBUDGHRSWFAEYSSLK2Y3ZHYI23GCMV5XGWXYHFMGHZKCM62II";

// hz 
// let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJIVlg1QzQ1UlNYSk1ZVllKVkhTQ1VINVRQUFM0TkxCRjdMRVRJSFdEQzVTQzVaNVBKVEdRIiwiaWF0IjoxNjM2NDU4ODc2LCJpc3MiOiJBQUFESzZOQk9GTlM2M1ZPWlFFRUlOS0kzWEdSRVkyVjVLRldHUlBGN0QyUFRJSFlQQTJVQjROWCIsIm5hbWUiOiJzd2FuX3Rlc3RfVTIiLCJzdWIiOiJVQUlIWEhKT0dLUDVFM041VFVYQkdDWkNERkRQU0JKNVRQNElYWkpENEo2UkJNTERCQ1pUQjNQMyIsIm5hdHMiOnsicHViIjp7fSwic3ViIjp7fSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.a-TnQUD29tUswS5M_W7QSe9jd2ueJIfiQ0b_gegOpdX1uKZHRsRylqefU7YA9z_HmW7Glp7xI4HC3OSMdZ-lAA";
// let useed = "SUALSYROZA5DPZRNNZX5X2REZB7DG2YKMIYCJY2EBHKBKTMMJNQCXEPJD4";


// new user from rn
let jwt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJXQktXRVRYSjdCUVVEUUlaU1ZMRU1NQVk3TkJWN0pEM0tEVldBSEJQQk40Q0lZQUVUQUtRIiwiaWF0IjoxNjM2OTc4OTE2LCJpc3MiOiJBQkdQT1RFU0o1VzZWN05URVpNSTc3WU5TQ01TSFlJSTRGTVhRM0dPNjdNTlo0RzZMRUpDUTNOVyIsIm5hbWUiOiIxYTM1YTkwODJmMDMyNzM5Yzk5YjZiNTQzZmE4NTViNSIsInN1YiI6IlVET0FDWFlUSUZKUERSVkZYVVpJT1JKNlM3VFZZRFlTRTVXN1dISVNUTDVDMklMVDY0VFhISlZKIiwibmF0cyI6eyJwdWIiOnsiZGVueSI6WyJcdTAwM2UiXX0sInN1YiI6eyJhbGxvdyI6WyJkZXYuc3dhbi5jb3JlYXBpLlx1MDAzZSJdfSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.SaPr8qSmZD4iZyllhledeGIeNOFACSghdUOa-NyTFXlbVcix1LeUceqTeYu68KDdz_WYLNv6Be-dAFHIib49AA"
let useed = "SUAFMFLAJZECOLJQW4KKCXCN7ZLB4V27HJPCAAHEO5MFI2XMQ3YH6JHU24";
const creds = `-----BEGIN NATS USER JWT-----
eyJ0eXAiOiJKV1QiLCJhbGciOiJlZDI1NTE5LW5rZXkifQ.eyJqdGkiOiJXQktXRVRYSjdCUVVEUUlaU1ZMRU1NQVk3TkJWN0pEM0tEVldBSEJQQk40Q0lZQUVUQUtRIiwiaWF0IjoxNjM2OTc4OTE2LCJpc3MiOiJBQkdQT1RFU0o1VzZWN05URVpNSTc3WU5TQ01TSFlJSTRGTVhRM0dPNjdNTlo0RzZMRUpDUTNOVyIsIm5hbWUiOiIxYTM1YTkwODJmMDMyNzM5Yzk5YjZiNTQzZmE4NTViNSIsInN1YiI6IlVET0FDWFlUSUZKUERSVkZYVVpJT1JKNlM3VFZZRFlTRTVXN1dISVNUTDVDMklMVDY0VFhISlZKIiwibmF0cyI6eyJwdWIiOnsiZGVueSI6WyJcdTAwM2UiXX0sInN1YiI6eyJhbGxvdyI6WyJkZXYuc3dhbi5jb3JlYXBpLlx1MDAzZSJdfSwic3VicyI6LTEsImRhdGEiOi0xLCJwYXlsb2FkIjotMSwidHlwZSI6InVzZXIiLCJ2ZXJzaW9uIjoyfX0.SaPr8qSmZD4iZyllhledeGIeNOFACSghdUOa-NyTFXlbVcix1LeUceqTeYu68KDdz_WYLNv6Be-dAFHIib49AA
------END NATS USER JWT------

************************* IMPORTANT *************************
NKEY Seed printed below can be used to sign and prove identity.
NKEYs are sensitive and should be treated as secrets.

-----BEGIN USER NKEY SEED-----
SUAFMFLAJZECOLJQW4KKCXCN7ZLB4V27HJPCAAHEO5MFI2XMQ3YH6JHU24
------END USER NKEY SEED------

*************************************************************
`

function App() {
  // const styles = useStyles();
  const [scene, setScene] = useState('start');
  const [process_id, setProcessId] = useState(''); 
  const [nc, setNc] = useState(null); 
  console.log('nc', nc)

  // const natsConnect = async () => {
  //   setNc(await connect(NATS_CONFIG)); 
  // }
  const natsConnect = async () => {
    try {
      // console.log('jwtAuthenticator', jwtAuthenticator(jwt, new TextEncoder().encode(useed))(),)
      setNc(await connect(NATS_CONFIG)); 
    } catch (err) {
      console.error('natsCOnnect err', err);
    }
  }

  const natsSubscribe = async () => {
    // console.log('nc', nc.info)
    const sc = JSONCodec();
    // const sub = nc.subscribe(`camunda.1.${process_id}`); 
    console.log('subscribe to swan.test.u1')
    const sub = nc.subscribe(`swan.test.u1`); 
    (async () => {
      for await (const m of sub) {
        console.log('SUBSCRIBE1',`[${sub.getProcessed()}]: ${sc.decode(m.data)}`);
        // console.log(`[${sub.getProcessed()}]: ${sc.decode(m.data).action}`);
        // setScene(sc.decode(m.data).action);
      }
    })();
    nc.closed()
    .then((err) => {
      if (err) {
        console.error(`service exited because of error: ${err.message}`);
      }
    });
  }

  useEffect(() => {
    natsConnect()
  }, [])
  
  useEffect(() => {
    console.log('subscribe', process_id, nc)
    if (!!nc) {
      // natsSubscribe()
    }
    if (!!process_id && !!nc) {
      // natsSubscribe()
    }
  }, [process_id, nc, natsSubscribe])

  const submitHoliday = async (selectedOption) => {
    const response = await axios.post('http://3.67.42.51:4000/api/do-action/accept-choice', {
      isHoliday: selectedOption,
      process_id
    })
    console.log('response', response.data);
    // if (response.data.IS_FAILED === 0 && response.data.msg === 'Task Complete!') {
    //   setScene('SHOW_GOOD_JOB_PAGE')
    // } else if (response.data.IS_FAILED === 0 && response.data.msg !== 'Task Complete!') {
    //   setScene('SHOW_GOOD_REST_PAGE')
    // }
  };

  return (
    <div className="App">
      <header className="App-header">

        {scene === 'start' && <Button 
          // className={styles.button}
          onClick={async () => { 
            const response = await axios.post('http://3.67.42.51:4000/api/start-survey')
            console.log('response', response.data);
            if (response.data?.IS_FAILED === 0 && response.data?.msg === "Process 'employee_survey' started!") {
              setProcessId(response.data?.dataset?.process_id)
              // setScene('SHOW_HOLIDAY_PAGE')
            }
        }}>start</Button>}

        {scene === 'SHOW_HOLIDAY_PAGE' && <Box>
          <Typography>Want a Holiday?</Typography>
          <Button
            onClick={() => submitHoliday(true)}
            >yes</Button>
          <Button 
            onClick={() => submitHoliday(false)}
            >no</Button>
        </Box>}

        {scene === 'SHOW_GOOD_JOB_PAGE' && <Box>
          <Typography>HAVE A GOOD JOB</Typography>
          <Button  onClick={() => {setProcessId(''); setScene('start')}}>go back</Button>
        </Box>}

        {scene === 'SHOW_GOOD_REST_PAGE' && <Box>
          <Typography>HAVE A GOOD HOLIDAY</Typography>
          <Button  onClick={() => {setProcessId(''); setScene('start')}}>go back</Button>
        </Box>}
      </header>
    </div>
  );
}

export default App;

